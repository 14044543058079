<template>
      <div class="box">
        <div class="flexspb toptitle">
          <div class="title flexcent">{{ nowdata.title }}</div>
        </div>
         <div class="flexcent time" v-if="nowdata.createTime">
           发布日期： {{nowdata.createTime| FormatDate('yyyy-MM-dd')}}
         </div>  
        <div>{{ nowdata.description }}</div>
        <div class="flexspb headbox">
          <div v-for="(item, index) in nowdata.types" :key="index">
            <div class="flexcent" v-if="index == 0">
              <el-tag v-if="item.respScene">{{ item.respScene }}</el-tag>
              <el-tag v-if="item.respTechnology">{{
                item.respTechnology
              }}</el-tag>
              <el-tag v-if="item.respTypes">{{ item.respTypes }}</el-tag>
            </div>
          </div>
          <div class="flexcen righboxt">
            <el-tag
              type="info"
              v-for="(item, index) in nowdata.formats"
              :key="index"
              >{{ item }}</el-tag
            >
            <i v-if="nowdata.dataSize" class="el-icon-s-data"></i>
            <span v-if="nowdata.dataSize" title="数据规模">
              <span v-if="nowdata.dataSize >= 1000"
                >{{ setnum(nowdata.dataSize / 1000) }}k</span
              >
              <span v-if="nowdata.dataSize < 1000">{{ nowdata.dataSize }}</span>
            </span>
            <i v-if="nowdata.file" class="el-icon-folder-checked"></i>
            <span v-if="nowdata.file" title="数据集大小">
              <span
                v-if="nowdata.file.size >= 1024 && nowdata.file.size < 1048576"
                >{{ setnum(nowdata.file.size / 1024) }}K</span
              >
              <span
                v-if="
                  nowdata.file.size >= 1048576 && nowdata.file.size < 1073741824
                "
                >{{ setnum(nowdata.file.size / 1048576) }}M</span
              >
              <span v-if="nowdata.file.size >= 1073741824"
                >{{ setnum(nowdata.file.size / 1073741824) }}G</span
              >
              <span v-if="nowdata.file.size < 1024"
                >{{ nowdata.file.size }}B</span
              >
            </span>
            <i v-if="nowdata.downloadNum" class="el-icon-download"></i>
            <span v-if="nowdata.downloadNum" title="下载次数">{{
              nowdata.downloadNum
            }}</span>
          </div>
        </div>
        <div class="filebox">
          <div class="flexspb filetop">
            <span class="leftfile">文件</span>
            <div v-if="username=='chisean'" class="downbtn flexcent" @click="todown">下载</div> 
          </div>
          <div class="pagebox">
            <div v-for="(item, index) in nowdata.fileVos" :key="index">
              {{ item.name }}
            </div>
          </div>
        </div>
        <!-- 文档 -->
        <!-- <div class="textbox">
          <div class="topack flexspb">
            <i class="el-icon-document"></i>
            <div>README</div>
          </div>
          <div class="bombox">
            <span v-html="gettitle(nowdata.readme)"></span>
          </div>
        </div> -->
      </div>
   
</template>

<script>
var showdown = require("showdown"),
converter = new showdown.Converter();

export default {
  name: 'detailModel',
 
  props: {
    username: {
      typeof: [String],
      default: '',
    },
  },
  data() {
    return {   
      id: '',
      primary: 1, //一级菜单的选中样式
      istrue: true, //刷新页面
      crumblist: [
        {
          name: "首页",
          path: "/home",
          query: {},
        },
        {
          name: "数据集和模型",
          path: "/dataset",
          query: {},
        },
        {
          name: "详细信息",
          path: "",
          query: {},
        },
      ],
      nowdata:{},
    }
  },
  
  created() {
    console.log(" this.username " , this.username)
    this.init()
    this.getdata();
  },
  methods: {
    init(){
      this.id = this.$route.params.id
      console.log("   dataset id " ,   this.id)
    },
    getdata() {
      this.$api.getDatasetDetail(this.id).then((res) => {
        console.log("  get data " , res)
        this.nowdata = res.data;
        // this.title = this.nowdata.title
      });
    },
    setnum(num) {
      var list = (num + ".").split(".");
      if (list.length == 3) {
        return list[0] + "." + list[1].slice(0, 2);
      }
    },
    todown() {
      var path =  this.$base.baseUrl + "/data-repository-api/api/repo/download/" + this.id;
      //  进行下载
      console.log(" download path " + path)
      this.until.jump.call(this, path, "href");
    },

     gettitle(value) {
      var html = converter.makeHtml(value);

      if (value && value.indexOf("blog:") > -1) {
        var text = html.split("blog:")[1].split("</p>")[0];
        var a = '<a href="'+ text+'">'+text+'</a>';
        return html.split(text).join(a)
      }else{
      return html
        
      }
    },

  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1000px) {
  .box {
    min-height: 60vh;
    .filebox {
      margin: 1.25rem 0 3.125rem;
      padding-top: 0.625rem;
      border-top: 0.0625rem solid #ececec;
      .filetop {
        margin-bottom: 1.875rem;
        .leftfile {
          font-size: 0.9rem;
          font-weight: 550;
        }
      }
    }
    .textbox {
      margin: 1rem 0;
      border: 0.0625rem solid #e5e5e5;
      border-radius: 0.25rem;
      .topack {
        justify-content: flex-start;
        height: 2.5rem;
        font-size: 1rem;
        background-color: #fafafa;
        border-bottom: 0.0625rem solid #e5e5e5;
        i {
          margin: 0 0.3125rem 0 1.25rem;
        }
        div {
          font-weight: 550;
        }
      }
      .bombox {
        padding: 1.875rem 3.125rem;
        min-height: 5rem;
        /deep/ p{
          margin-top: .65rem;
        }
      }
    }
    .pagebox {
      font-size: 0.9rem;
      padding-left: 20px;
      color: #474747;
      div {
        margin-bottom: 0.3125rem;
      }
    }
    .downbtn {
      cursor: pointer;
      width: 5rem;
      height: 1.875rem;
      border-radius: 0.25rem;
      color: #ffffff;
      background-color: #3493d3;
      font-size: 1rem;
    }
    .toptitle {
      height: 4rem;
      font-weight: 550;
      font-size: 1.5rem;
      .title {
        flex: 1;
        margin-left: 0rem;
      }

      .downbtn:hover {
        background-color: #1058f1;
      }
      .downbtn:active {
        opacity: 0.8;
      }
    }
    .headbox {
      height: 3.75rem;
      .el-tag {
        height: 1.25rem;
        line-height: 1rem;
        margin-right: 0.625rem;
        padding: 0 0.5rem;
      }
    }
    .righboxt {
      font-size: 0.875rem;
      color: #0063ff;
      cursor: pointer;
      i {
        margin: 0 0.3125rem 0 0.625rem;
      }
    }
    .time {
      // height: 3rem;
      font-size: 0.875rem;
      color: #2e2e2e;
      margin-bottom: 1rem;
    }
  }
}

@media screen and (max-width: 999px) {
   .box {
    min-height: 60vh;
    .filebox {
      margin: 1.25rem 0 3.125rem;
      padding-top: 0.625rem;
      border-top: 0.0625rem solid #ececec;
      .filetop {
        margin-bottom: 1.875rem;
        .leftfile {
          font-size: 0.9rem;
          font-weight: 550;
        }
      }
    }
    .textbox {
      margin: 1rem 0;
      border: 0.0625rem solid #e5e5e5;
      border-radius: 0.25rem;
      .topack {
        justify-content: flex-start;
        height: 2.5rem;
        font-size: 1rem;
        background-color: #fafafa;
        border-bottom: 0.0625rem solid #e5e5e5;
        i {
          margin: 0 0.3125rem 0 1.25rem;
        }
        div {
          font-weight: 550;
        }
      }
      .bombox {
        padding: 1.875rem 3.125rem;
        min-height: 5rem;
        /deep/ p{
          margin-top: .65rem;
        }
      }
    }
    .pagebox {
      font-size: 0.9rem;
      padding-left: 20px;
      color: #474747;
      div {
        margin-bottom: 0.3125rem;
      }
    }
    .downbtn {
      cursor: pointer;
      width: 5rem;
      height: 1.875rem;
      border-radius: 0.25rem;
      color: #ffffff;
      background-color: #3493d3;
      font-size: 1rem;
    }
    .toptitle {
      height: 4rem;
      font-weight: 550;
      font-size: 1.5rem;
      .title {
        flex: 1;
        margin-left: 0rem;
      }

      .downbtn:hover {
        background-color: #1058f1;
      }
      .downbtn:active {
        opacity: 0.8;
      }
    }
    .headbox {
      height: 3.75rem;
      .el-tag {
        height: 1.25rem;
        line-height: 1rem;
        margin-right: 0.625rem;
        padding: 0 0.5rem;
      }
    }
    .righboxt {
      font-size: 0.875rem;
      color: #0063ff;
      cursor: pointer;
      i {
        margin: 0 0.3125rem 0 0.625rem;
      }
    }
    .time {
      // height: 3rem;
      font-size: 0.875rem;
      color: #2e2e2e;
      margin-bottom: 1rem;
    }
  }
}

</style>
